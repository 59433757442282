<template lang="html">
  <div class="static-intro">
    <Back color="#C99D66" label="">
      <Heading
        class="static-intro__heading"
        v-if="initialData.heading"
        :text="initialData.heading"
        :hType="1"
      />
    </Back>
    <div
      class="static-intro__description"
      v-if="initialData['description_1']"
      v-html="initialData['description_1']"
    ></div>
    <div
      class="static-intro__intro"
      v-if="initialData.intro"
      v-html="initialData.intro"
    ></div>
    <div
      class="static-intro__description"
      v-if="initialData['description_2']"
      v-html="initialData['description_2']"
    ></div>
  </div>
</template>

<script>
import Back from '@/components/Back/Back';
import Heading from '@/components/Heading/Heading';

export default {
  name: 'StaticIntro',
  components: { Heading, Back },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
